/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
// Components
import SEO from 'components/SEO'
import { Layout, Hero, Hero2, CTA, Article, Services } from 'components/shared'
import { Heading, WhatsappButton, Button } from 'components/elements'

const BottomSection = styled.div`
  padding-top: 80px;
  margin-bottom: 70px;
  @media screen and (max-width: 1060px) {
    margin-top: 50px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
`
const HomeTemplate = ({
  data: {
    page: { path, yoastMeta, acf },
  },
}) => {
  return (
    <Layout headerActive="Home">
      {yoastMeta && (
        <SEO
          yoast={{ meta: yoastMeta }}
          pathname={path}
          image={acf.banner.image.localFile.childImageSharp.fluid.src}
          article
        />
      )}

      <Hero backgroundImg={acf.banner.image} title={acf.banner.title} />

      <CTA text={acf.cta.description} button={acf.cta.button} />
      <WhatsappButton />
      <Services ids={acf.service_relations.relations} />
      <Article
        title={acf.meta.title}
        description={acf.meta.description}
        button={acf.meta.button}
      />
      <BottomSection>
        <Heading text={acf.projects.title} className="mt-1" />
        <Hero2
          backgroundImg={acf.projects.image}
          title={acf.projects.description}
        />
        <div className="d-flex justify-content-center mt-5">
          <Button
            to={acf.projects.button.url}
            className="mx-auto"
            label={acf.projects.button.title}
            shadowColor="#D8D8D8"
          />
        </div>
      </BottomSection>
    </Layout>
  )
}

export default HomeTemplate
